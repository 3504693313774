/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Box } from "rebass"
import { RichText } from "prismic-reactjs"
import { truncate } from "lodash"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container } from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"

const Announcement = () => {
  const data = useStaticQuery(graphql`
    {
      prismic {
        allGeneral_noticess {
          edges {
            node {
              title
              content
              is_highlighted
              _meta {
                id
                uid
              }
            }
          }
        }
        allEmergency_noticess {
          edges {
            node {
              title
              content
              is_highlighted
              _meta {
                id
                uid
              }
            }
          }
        }
        allInterruptions_noticess {
          edges {
            node {
              title
              content
              is_highlighted
              _meta {
                id
                uid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      {data.prismic.allGeneral_noticess.edges.map(({ node: gnotice }) => (
        <>
          {gnotice.is_highlighted === true && (
            <Box
              sx={{
                backgroundColor: "red.1",
                p: 3,
                color: "red.7",
              }}
            >
              <Container>
                <Box
                  as="p"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {RichText.asText(gnotice.title)}
                </Box>
                <p>{`${truncate(RichText.asText(gnotice.content), {
                  length: 180,
                  omission: " [...]",
                })}`}</p>
                <Link to={`notices/general/${gnotice._meta.uid}`}>
                  More details
                </Link>
              </Container>
            </Box>
          )}
        </>
      ))}

      {data.prismic.allInterruptions_noticess.edges.map(({ node: inotice }) => (
        <>
          {inotice.is_highlighted === true && (
            <Box
              sx={{
                backgroundColor: "red.1",
                p: 3,
                color: "red.7",
              }}
            >
              <Container>
                <Box
                  as="p"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {RichText.asText(inotice.title)}
                </Box>
                <p>{`${truncate(RichText.asText(inotice.content), {
                  length: 180,
                  omission: " [...]",
                })}`}</p>
                <Link to={`notices/interruptions/${inotice._meta.uid}`}>
                  More details
                </Link>
              </Container>
            </Box>
          )}
        </>
      ))}

      {data.prismic.allEmergency_noticess.edges.map(({ node: enotice }) => (
        <>
          {enotice.is_highlighted === true && (
            <Box
              sx={{
                backgroundColor: "red.1",
                p: 3,
                color: "red.7",
              }}
            >
              <Container>
                <Box
                  as="p"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {RichText.asText(enotice.title)}
                </Box>
                <p>{`${truncate(RichText.asText(enotice.content), {
                  length: 180,
                  omission: " [...]",
                })}`}</p>
                <Link to={`notices/emergency/${enotice._meta.uid}`}>
                  More details
                </Link>
              </Container>
            </Box>
          )}
        </>
      ))}
    </>
  )
}

export default Announcement
